import React from "react";
import "./App.css";

// Import images
import dental1 from "./assets/images/community_service.jpg";
import dental2 from "./assets/images/school_campaign.jpg";
import dental3 from "./assets/images/removable_partial_denture.jpg"

import art1 from "./assets/images/Penn Badgley.jpg";
import art2 from "./assets/images/Milly Alcock.jpg";

import { FaEnvelope, FaLinkedin } from "react-icons/fa";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <nav>
          <ul>
            <li><a href="#main">Home</a></li>
            <li><a href="#dental">Dental Works</a></li>
            <li><a href="#arts">Arts</a></li>
          </ul>
        </nav>
      </header>

      <main id="main" className="main-section">
        <h1>✨ Hello, I am Ritika Shrestha ✨</h1>
        <p>🦷 Dentist by profession and 🎨 artist by heart.</p>
        <a
            href="https://www.linkedin.com/in/ritikashrestha"
            target="_blank"
            rel="noopener noreferrer"
            className="contact-button"
          >
            <FaLinkedin className="contact-icon" />  LinkedIn
          </a> &nbsp; 
        <a href="mailto:ritikaashrestha@gmail.com" className="contact-button">
          <FaEnvelope className="contact-icon" /> Contact Me
        </a>
        
      </main>

      <section id="dental" className="gallery">
        <h2>🦷 Dental Works</h2>
        <div className="card">
          <img src={dental2} alt="Dental Work 1" />
          <p>Instructions for Oral Hygiene Maintainance</p>
        </div>
        <div className="card">
          <img src={dental1} alt="Dental Work 2" />
          <p>Community School Dental Camp</p>
        </div>
        <div className="card">
          <img src={dental3} alt="Dental Work 2" />
          <p>Smile makeover with removable partial denture.</p>
        </div>
      </section>

      <section id="arts" className="gallery">
        <h2>🎨 Arts</h2>
        <div className="card">
          <img src={art1} alt="Art 1" />
          <p>Potrait sketch of Penn Badgley</p>
        </div>
        <div className="card">
          <img src={art2} alt="Art 2" />
          <p>Portrait sketch of Milly Alcock.</p>
        </div>
      </section>

      <footer>
        <p>💕 Made with love by Ritika Shrestha</p>
      </footer>
    </div>
  );
}

export default App;
